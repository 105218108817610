import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  isOpen?: boolean;
  toggleMenu?: () => void;
}

export const NavLinks = ({ isOpen, toggleMenu }: Props) => {
  const navlinks = [
    {
      link: "/",
      linkText: "Inicio",
    },
    // {
    //   link: "/nosotras",
    //   linkText: "Nosotras",
    // },
    {
      link: "/contacto",
      linkText: "Contacto",
    },
  ];
  return (
    <div
      className={`navbar-link-container ${isOpen ? "open" : ""}`}
      onClick={toggleMenu}
    >
      {navlinks.map((link) => (
        <NavLink key={link.link} className="navbar-link" to={link.link}>
          {link.linkText}
        </NavLink>
      ))}
    </div>
  );
};
