import "./footer.css";
import { NavLinks } from "../navbar/NavLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const contacts = [
  {
    text: "318 5954301",
    icon: faWhatsapp,
    linkTo: "https://wa.me/3185954301/?text=Hola!%20Estoy%20interesado%20en%20más%20información%20de%20tu%20portafolio.%20Gracias"
  },
  {
    text: "paola.martinez@mygingenieria.com",
    icon: faEnvelope,
    linkTo: "mailto:paola.martinez@mygingenieria.com"
  },
  {
    text: '@M&G.Ingeniería',
    icon: faInstagram,
    linkTo: 'https://www.instagram.com/myg.ingenieria/'
  }, 
  {
    text: 'M&G Ingeniería y Consultoría S.A.S',
    icon: faLinkedin,
    linkTo: 'https://www.linkedin.com/company/m-g-ingenier%C3%ADa-y-consultor%C3%ADa-sas/?viewAsMember=true'
  }, 
];

export const Footer = () => {
  const logo = `assets/logos/magrincoLogoTrans.png`
  return (
    <section className="footer-container">
      <div className="footer-container-logo">
        <img className="navbar-img" src={logo} alt="Magrinco Logo"/>
        <span className="logo-title">M&G-Ingenieria</span>
      </div>
      <div className="footer-container-contact">
        <h2 className="footer-content-title">Contáctenos</h2>

        {contacts.map((contact) => (
          <a href={contact.linkTo} key={contact.text} className="footer-contact-container" target="_blank" rel="noreferrer">
            <FontAwesomeIcon
              className="whatsappButton-container--icon"
              icon={contact.icon}
            />
            <span className="footer-contet-text">{contact.text}</span>
          </a>
        ))}
      </div>
      <div className="footer-container-content">
        <NavLinks />
      </div>
    </section>
  );
};
