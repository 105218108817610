import React, { useEffect } from "react";
import { Banner } from "../components/banner";
import "../styles/home.css";
import { MenuIcons } from "../components/menuIcons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "../components/carousel";
import ReactPlayer from "react-player";

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const childrenBackground = (
    <ReactPlayer
      className="react-player"
      muted
      playing
      loop
      url={"assets/videos/magrinco.mp4"}
      height="auto"
      width="auto"
    />
  );
  return (
    <div className="home-container">
      <Banner
        childrenBackground={childrenBackground}
        title="M&G Ingeniería y Consultoría S.A.S"
        text='"Transformando Desafíos Ambientales en Oportunidades: M&G Ingeniería tu socio en consultoría ambiental”'
      />
      <main className="home-main-container">
        <MenuIcons />
        <div className="aboutus-container">
          <h2 className="aboutus-title">¿Quiénes somos?</h2>
          <p className="aboutus-text">
            Somos una empresa que con dedicación, rigor
            técnico y calidad presta desde el año 2017 servicios de Ingeniería y
            Consultoría Ambiental. Nuestro equipo profesional cuenta con el
            conocimiento y la experiencia necesarios para brindar asesoría en el
            desarrollo adecuado de la gestión Ambiental y Legal
            de sus proyectos.
          </p>
        </div>

        <div className="our-clients-container">
          <h2 className="our-clients-title">Nuestros clientes</h2>
          <Carousel />
        </div>
      </main>
    </div>
  );
};
