import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {  AboutUsPage } from '../pages/AboutUsPage'
import { ContactPage} from '../pages/ContactPage'
import { Home } from '../pages/Home'
import { Navbar } from '../components/navbar'
import { Footer } from '../components/footer'
import { WhatsappButton } from '../components/socialMedia'
import { About } from '../pages/About'
import { Page404 } from '../pages/404-page'


export const AppRouter = () => {
  //TODO: hacer la pagina 404
  return (
    <>
    <Navbar />
    <Routes>
        <Route path='/' element={<Home/>}/>
        {/* <Route path='nosotras' element={<AboutUsPage/>}/> */}
        <Route path='contacto' element={<ContactPage/>}/> 
        <Route path='*' element={<Page404/>}/> 
        <Route path='about/:id' element={<About/>}/>  
    </Routes>
    <WhatsappButton/>
    <Footer />
    </>
  )
}
