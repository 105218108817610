import React from "react";
import { AppRouter } from "./router/AppRouter";
import { Helmet } from "react-helmet";

export const MagrincoApp = () => {
  return (
    <>
      <Helmet>
        <title>M&G-Ingenieria</title>
        <meta
          name="description"
          content="Somos una empresa que con dedicación, rigor técnico y calidad presta desde el año 2017 servicios de Ingeniería y Consultoría Ambiental. Nuestro equipo profesional cuenta con el conocimiento y la experiencia necesarios para brindar asesoría en el desarrollo adecuado de la gestión Ambiental y Legal de sus proyectos.
          En M&G Ingeniería y Consultoría ofrecemos acompañamiento y  soporte legal para garantizar el cumplimiento normativo en materia ambiental y sanitaria. Nos especializamos en la gestión de trámites,  permisos y autorizaciones asegurando el cumplimiento de los requisitos legales.  Contamos con la experiencia y disposición para responder a requerimientos normativos  de carácter ambiental
      
          Con nuestro enfoque integral de seguimiento y monitoreo, brindamos a nuestros clientes la tranquilidad de saber que están operando de manera responsable y en cumplimiento de la normatividad ambiental vigente. Prestamos los servicios de monitoreo de variables ambientales a través de alianzas estratégicas con laboratorios acreditados ante el IDEAM.

          La adaptación y la mitigación son dos estrategias fundamentales en la lucha contra el cambio climático. En términos simples, la adaptación se refiere a ajustarse a los impactos ya inevitables del cambio climático, mientras que la mitigación implica tomar medidas para principalmente reducir y/o prevenir esos impactos. En M&G Ingeniería y Consultoría, entendemos la importancia de ambas estrategias y trabajamos con nuestros clientes para desarrollar soluciones integrales que aborden estas necesidades, permitiendo una transición hacia una economía baja en carbono y más amigable con el medio ambiente.

          La mejora continua en los procesos ambientales es un pilar fundamental para cualquier organización comprometida con la sostenibilidad. En M&G Ingeniería y Consultoría, ofrecemos servicios especializados para ayudar a las empresas a identificar áreas de mejora en sus prácticas ambientales, trabajando en estrecha colaboración con nuestros clientes para desarrollar estrategias personalizadas que promuevan la eficiencia ambiental y la responsabilidad corporativa.

          En M&G Ingeniería y Consultoría creemos que, al educar a las personas sobre la importancia de la conservación de los recursos naturales y la protección del medio ambiente, podemos generar acciones tangibles que contribuyan a la preservación de nuestro planeta para las generaciones futuras. Ya sea a través de capacitaciones en empresas, actividades comunitarias o iniciativas escolares, estamos comprometidos a promover una cultura de sostenibilidad que transforme la manera en que interactuamos con nuestro entorno.

          A través de la medición, seguimiento y el análisis de datos, en M&G Ingeniería y Consultoría garantizamos la eficiencia y el cumplimiento de los estándares ambientales, permitiendo a nuestros clientes evaluar y mejorar su desempeño ambiental de manera continua."
        />
        <link rel="icon" href="%PUBLIC_URL%/assets/favicon.ico"></link>
      </Helmet>
      <AppRouter />
    </>
  );
};
